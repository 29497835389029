import { useIsInViewport } from '@kaliber/use-is-in-viewport'

import styles from './HashSetter.css'

export function HashSetter({ hash, overrideRootMargin = undefined, layoutClassName = undefined }) {
  const rootMargin = overrideRootMargin || '-50% 0%'
  const { ref: hashSetterRef, isInViewport } = useIsInViewport({ rootMargin })

  React.useMemo(() => {
    if (isInViewport) {
      setHash(hash)
    }
  }, [isInViewport, hash])

  return <div className={cx(styles.component, layoutClassName)} ref={hashSetterRef} />
}

/** @param {string} hash */
function setHash(hash) {
  const base = window.location.pathname + window.location.search
  window.history.replaceState({}, document.title, `${base}#${hash || ''}`)
}
